<template>
	<header
		class="header"
		v-cloak
		@click.self="closePopupchik">
		<div
			@click.self="closePopupchik"
			class="header__inner">
			<div class="georgianLine-wrapper">
				<!-- <img class="georgianLine-wrapper__image" src="/img/flag.png" /> -->
				<!-- <img class="georgianLine-wrapper__image" src="/img/georgianLine.png" /> -->
			</div>
			<router-link
				class="header-logo"
				to="/general"
				@click="store.dispatch('toggleArchive', false)">
				<!-- <img
					class="header-logo__img"
					src="/img/NewYear/LogoNG.svg" /> -->
				<img class="header-logo__img" src="/img/Logo.svg" />
				<div>
					<h2 class="header-logo__title">
						Аналитический центр города
					</h2>
					<span class="header-logo__subtitle"
						>Платформа «Лобачевский»</span
					>
				</div>
				<!-- <img src="/img/header/febuary-23.svg" alt="febuary 23" class="header-logo__img"> -->
				<!-- <img src="/img/header/8-mart.svg" alt="8-е марта" class="header-logo__img-special"> -->
			</router-link>
			<h1>
				{{ route.meta.name + store.state.sufix }}
				<div
					v-if="store.state.showDocBtn"
					class="wrapper"
					@click="store.commit('setHeaderEvent', 'documentation')">
					<div class="documentation">
						<i
							class="fa fa-info"
							title="Описание виджета"
							aria-hidden="true"></i>
					</div>
				</div>
			</h1>
			<div class="header__info">
				<div class="header-controls">
					<!-- <span v-if="['1', '2', '45', '83'].includes(newClientID)" class="btnCityReport">
            Показать отчёт
          </span> -->

					<!-- <span v-if="['1', '2', '45', '83'].includes(newClientID)" class="btnCityReport2">
            <img src="/img/cityReportPeriodImg.svg" alt="">
          </span> -->

					<span
						v-if="['1', '2', '45', '83', '112'].includes(newClientID)"
						class="header-controls--linkList">
						<img
							title="Ссылки"
							class="bug_icon"
							src="/img/header/link.svg"
							@click="togglePopupchik()"
							alt="link" />

						<div
							v-if="showPopupchik"
							class="popupchik">
							<a
								v-for="link in linkList"
								:href="link.link"
								target="_blank"
								class="popupchik--item">
								{{ link.title }}
							</a>
						</div>
					</span>

					<span v-if="['1', '2', '45'].includes(newClientID)">
						<img title="Презентация" class="bug_icon" src="/img/presentation.svg" @click="openWindow()" alt="" />
						<!-- <a
							title="Презентация"
							href="/presentation.pdf"
              target="_blank"
							class="bug_icon"
							alt=""
							><img
								title="Презентация"
								class="bug_icon"
								src="/img/presentation.svg"
						/></a> -->
					</span>

					<span v-if="['83'].includes(newClientID)">
						<img title="Презентация" class="bug_icon" src="/img/presentation.svg" @click="openWindow()" alt="" />
					</span>

					<span
						v-if="['1', '2', '45', '83'].includes(newClientID)"
						class="btnCityReport2">
						<!-- <a href="https://features.acgnn.ru/cityReportPeriod?apiKey=7n8otUBIuN&needInterval=true" target="_blank" title="Отчёт по городу"><img src="/img/cityReportPeriodImg.svg" alt=""></a> -->
						<a
							href="https://features.acgnn.ru/cityReportPeriod?apiKey=7n8otUBIuN"
							target="_blank"
							title="Отчёт по городу"
							><img
								src="/img/cityReportPeriodImg.svg"
								alt=""
						/></a>
					</span>

					<span
						v-if="route.path === '/general'"
						title="Скрытие групп">
						<img
							v-if="
								!isAllGroupsClosed && !store.state.homeTableView
							"
							@click="openAllCloseGroups(false)"
							src="/img/header/closeGroups.svg"
							alt="" />
						<img
							v-if="
								isAllGroupsClosed && !store.state.homeTableView
							"
							@click="openAllCloseGroups(true)"
							src="/img/header/openGroups.svg"
							alt="" />
					</span>

					<span title="Обратная связь">
						<a
							href="https://opros.acgnn.ru/execForm?form_uuid=741c685f-17d8-4891-842b-0633bc0e9184"
							target="_blank">
							<img
								class="bug_icon"
								src="/img/bug.svg"
								alt="" />
						</a>
					</span>

					<span v-if="!['209'].includes(newClientID)" title="Отчёт">
						<!--a href="https://features.acgnn.ru/uploadingStatistics?apiKey=7n8otUBIuN" target="_blank" -->
						<a
							href="https://features.acgnn.ru/uploadingStatistics?apiKey=7n8otUBIuN"
							target="_blank">
							<img
								src="/img/headerReportIcon.svg"
								alt="" />
						</a>
					</span>

					<span
						v-if="store.state.isDeveloper"
						title="Кабинет">
						<a
							href="https://platform_old.acgnn.ru/admin/pages/menedgers.php">
							<img
								src="/img/headerSettingsIcon.svg"
								alt="" />
						</a>
					</span>

					<span
						v-if="
							['1', '45', '83'].includes(newClientID) &&
							route.path === '/general'
						"
						class="swap-archive"
						@click="changeArchiveView"
						title="Архив">
						<img
							src="/img/archiveImg.svg"
							alt="" />

						<div
							v-if="store.state.showArchive"
							class="indicator green"></div>
					</span>

					<span
						class="swap-home-view"
						@click="swapView"
						title="Вид">
						<img
							v-if="
								store.state.homeTableView &&
								route.path === '/general'
							"
							src="/img/headerWidgetsListIcon.svg"
							alt="" />
						<img
							v-if="
								!store.state.homeTableView &&
								route.path === '/general'
							"
							src="/img/headerWidgetsTableIcon.svg"
							alt="" />
					</span>

					<span title="Выход">
						<img
							src="/img/headerExitIcon.svg"
							alt=""
							@click="exit"
							class="exit-buttton" />
					</span>
				</div>
			</div>
		</div>
	</header>

	<div
		class="modalWindow"
		id="modalWindow"
		style="display: none"
		@click="closeWindow()">
		<div
			class="modalWindow__close"
			@click="closeWindow()">
			✖
		</div>
		<div
			class="modalWindow__body"
			@click.stop>
			<slider @closeWindow="closeWindow" />
		</div>
	</div>
</template>

<script setup>
import { useRoute } from "vue-router";
// import { ref, onMounted, computed, components } from "vue";
import { computed, ref } from "vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import scrollToScrollPositionInStore from "@/helpers/scrollToScrollPositionInStore";

import slider from "@/components/the/slider/slider.vue";

import "@/helpers/sendLogs.js";
import "@/helpers/userAlive.js";
import axios from "axios";

const route = useRoute();

const isAllGroupsClosed = computed(() =>
	Object.values(store.state.groupsOpening).reduce(
		(total, x) => total && !x,
		true
	)
);

let newClientID = computed(() => store.state.newClientID);

const showPopupchik = ref(false);
const linkList = ref([]);
function togglePopupchik() {
	showPopupchik.value = !showPopupchik.value;
}
function closePopupchik() {
	showPopupchik.value = false;
}
function loadLinks() {
	axios({
		method: "get",
		url: "https://restapi.acgnn.ru/api/v1/platformLinks",
	}).then((res) => {
		linkList.value = res.data;
	});
}
loadLinks();

function changeArchiveView() {
	store.dispatch("toggleArchive");
	store.commit("openAllCloseGroups", true);
}

function exit() {
	document.cookie.split(";").forEach(function (c) {
		document.cookie = c
			.replace(/^ +/, "")
			.replace(
				/=.*/,
				"=;expires=" + new Date().toUTCString() + ";path=/"
			);
	});
	store.commit("signOutDeveloper");
	router.replace("/");
}

function disLogin() {
	document.cookie.split(";").forEach(function (c) {
		document.cookie = c
			.replace(/^ +/, "")
			.replace(
				/=.*/,
				"=;expires=" + new Date().toUTCString() + ";path=/"
			);
	});
	router.replace("/");
}

function swapView() {
	store.commit("toggleHomeView");
	store.commit("clearLoadingChartData");
}

function openAllCloseGroups(value) {
	store.commit("openAllCloseGroups", value);
}

router.afterEach((to, from) => {
	scrollToScrollPositionInStore();
});

function openWindow() {
	document.getElementById("modalWindow").style.display = "flex";
}

function closeWindow() {
	document.getElementById("modalWindow").style.display = "none";
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
